<template>
  <fragment>
    <h1 class="main-title mb-4 d-flex align-center">
      <span class="main-title__text">
        {{ $t("dashboard.productSampling.title") }}
      </span>
    </h1>
    <v-row class="mx-0">
      <p v-if="product" class="wx-typo-h3 mb-0">{{ getProductDisplayName(product) }}</p>
      <v-row class="mx-0 mb-2 mt-0" justify="end">
        <p class="ma-0">
          <span class="font-weight-medium">{{ $t("common.date") }}: </span>
          <span>{{ formattedDate }}</span>
        </p>
        <v-divider vertical class="mx-5" />
        <p class="ma-0">
          <span class="font-weight-medium">{{ $t("common.time") }}: </span>
          <span>{{ formattedTime }}</span>
        </p>
      </v-row>
    </v-row>
    <v-row class="mx-0" justify="end">
      <p class="text--disabled" v-if="samplingSessionInProgress">
        {{ $t("common.sessionId") }} : {{ samplingSessionInProgress.session_id }}
      </p>
      <v-spacer />
      <p>
        {{ $t("dashboard.productSampling.sessionExpire") }}:
        <span class="ml-5 font-weight-medium" :class="timerColor">{{ formattedTimer }}</span>
      </p>
    </v-row>
    <samples
      ref="samples"
      v-if="product"
      :sample-count="sampleCount"
      :product="product"
      @cancel="() => (cancellingSession = true)"
      @submit="() => (submittingSession = true)"
    />
    <instructions />
    <session-expired-dialog :show="sessionExpired" @back="back" @restart="restart" />
    <confirm-cancel-dialog :show="cancellingSession" @cancel="cancel" @back="() => (cancellingSession = false)" />
    <confirm-submit-dialog :show="submittingSession" @submit="submit" @back="() => (submittingSession = false)" />
  </fragment>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ProductService from "@/components/product/ProductService";
import SessionExpiredDialog from "@/components/productsamplingsession/dialogs/SessionExpiredDialog.vue";
import ConfirmCancelDialog from "@/components/productsamplingsession/dialogs/ConfirmCancelDialog.vue";
import Instructions from "@/components/productsamplingsession/Instructions";
import Samples from "@/components/productsamplingsession/Samples";
import GiveawaySamplingService from "@/components/productsamplingsession/GiveawaySamplingService";
import RouteService from "@/router/RouteService";
import ConfirmSubmitDialog from "@/components/productsamplingsession/dialogs/ConfirmSubmitDialog.vue";

const TIME_PER_SAMPLE = 120;

export default {
  name: "ProductSamplingView",
  components: {
    ConfirmSubmitDialog,
    SessionExpiredDialog,
    Instructions,
    Samples,
    ConfirmCancelDialog,
  },
  data() {
    return {
      product: null,
      now: null,
      timer: TIME_PER_SAMPLE,
      sampleCount: null,
      sessionExpired: false,
      cancellingSession: false,
      submittingSession: false,

      timerInterval: null,
      timezone: null,
    };
  },
  watch: {
    timer() {
      if (this.timer <= 0) {
        clearInterval(this.timerInterval);
        this.$refs.samples.clearFetchSamplesInterval();
        this.sessionExpired = true;
      }
    },
  },
  computed: {
    ...mapGetters("dashboard", ["activeProductionUnit", "samplingSessionInProgress", "activeProductionUnitId"]),
    ...mapGetters("navigation", ["activeFactory"]),
    formattedTimer() {
      const minutes = parseInt(this.timer / 60);
      const seconds = parseInt(this.timer % 60);
      const minutesStr = minutes >= 10 ? `${minutes}` : `0${minutes}`;
      const secondsStr = seconds >= 10 ? `${seconds}` : `0${seconds}`;
      return `${minutesStr}:${secondsStr}`;
    },
    timerColor() {
      return this.timer > 30 ? null : "red-timer";
    },
    localeDateString() {
      if (!this.now || !this.timezone) return null;

      return this.now.toLocaleString("en-CA", {
        timezone: this.timezone,
        hour12: false,
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      });
    },
    formattedDate() {
      if (!this.localeDateString) return "";

      return this.localeDateString.split(", ")[0];
    },
    formattedTime() {
      if (!this.localeDateString) return "";

      return this.localeDateString.split(", ")[1];
    },
  },
  methods: {
    ...mapActions("dashboard", ["setSamplingSessionInProgress"]),
    ...mapActions("operation", ["handleHttpError"]),
    submit() {
      GiveawaySamplingService.completeSamplingSession(
        this.activeProductionUnitId,
        this.samplingSessionInProgress.session_id,
      )
        .then(() => this.$router.replace(RouteService.toDashboard(this.activeProductionUnitId)))
        .catch(this.handleHttpError);
    },
    fetchProduct() {
      ProductService.getProduct(this.$route.params.id, false)
        .then((httpResponse) => {
          this.product = httpResponse.data;
        })
        .catch((httpError) => {
          this.$router.replace(RouteService.toDashboard(this.activeProductionUnitId));
          this.handleHttpError(httpError);
        });
    },
    startTimer() {
      const initialTimer = (this.samplingSessionInProgress.end_time - this.samplingSessionInProgress.start_time) / 1000;
      const adjustedTimer = (Date.now() - this.samplingSessionInProgress.start_time) / 1000;
      this.timer = initialTimer - adjustedTimer;
      this.timerInterval = setInterval(() => {
        this.timer--;
      }, 1000);
    },
    restart() {
      // create a new session and reset variables
      GiveawaySamplingService.createSamplingSession(this.activeProductionUnitId, {
        sku: this.samplingSessionInProgress.sku,
      }).then((httpResponse) => {
        this.setSamplingSessionInProgress(httpResponse.data.resource);
        this.now = new Date(this.samplingSessionInProgress.start_time);
        this.sessionExpired = false;
        this.startTimer();
      });
    },
    cancel() {
      GiveawaySamplingService.cancelSamplingSession(
        this.activeProductionUnitId,
        this.samplingSessionInProgress.session_id,
      )
        .then(() => {
          this.setSamplingSessionInProgress(null);
          this.$router.replace(RouteService.toDashboard(this.activeProductionUnitId));
        })
        .catch(this.handleHttpError);
    },
    back() {
      this.$router.replace(RouteService.toDashboard(this.activeProductionUnitId));
    },
    getProductDisplayName(product) {
      let productDescriptionString = product.description ? " (" + product.description + ")" : ""
      return product.sku + productDescriptionString;
    },
  },
  mounted() {
    if (!this.activeProductionUnit || !this.activeFactory || !this.samplingSessionInProgress) {
      // this means the page was refreshed
      return this.$router.replace(RouteService.toDashboard(this.activeProductionUnitId));
    }
    this.sampleCount = this.activeProductionUnit.giveaway_sampling_configuration.sample_count;
    this.timezone = this.activeFactory.timezone;
    this.now = new Date(this.samplingSessionInProgress.start_time);
    this.fetchProduct();
    this.startTimer();
  },
  beforeDestroy() {
    // clear the interval before destroying the component
    clearInterval(this.timerInterval);
  },
};
</script>

<style lang="scss" scoped>
.red-timer {
  color: red;
}
</style>
